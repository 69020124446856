/* eslint-disable camelcase */
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { checkAndGenerateName } from './name-generator';

const GRAND_RAPIDS_DEFAULT_LAT = 42.9634;
const GRAND_RAPIDS_DEFAULT_LNG = -85.6681;

export const pinLayerConfig = (pinType) => ({
  id: `${pinType}-locations`,
  type: 'symbol',
  source: `${pinType}-data`,
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': `${pinType}-pin`,
    'icon-size': 0.2,
    'icon-allow-overlap': true,
  },
});

export const clusterLayerConfig = (clusterType) => ({
  id: `${clusterType}-clusters`,
  type: 'symbol',
  source: `${clusterType}-data`,
  filter: ['has', 'point_count'],
  layout: {
    'icon-image': `${clusterType}-pin-group`,
    'icon-size': 0.19,
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 10,
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
  },
  paint: {
    'text-translate': [0, -3],
    'text-translate-anchor': 'viewport',
  },
});

export const geoJsonSourceConfig = (geoJson) => ({
  type: 'geojson',
  data: geoJson,
  promoteId: 'name',
  cluster: true,
  clusterMaxZoom: 14, // Max zoom to cluster points on
  clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
});

export const renderEmoji = (unicodeString) => {
  if (unicodeString?.slice(0, 2) === 'U+') {
    return String.fromCodePoint(parseInt(`0x${unicodeString?.slice(2)}`, 10));
  }
  if (unicodeString?.slice(0, 2) === '0x') {
    return String.fromCodePoint(parseInt(unicodeString, 10));
  }
  return '';
};

export const createEmojiElement = (feature) => {
  const emojiElement = document.createElement('div');
  const emojiIcon = document.createElement('div');
  emojiIcon.className = 'marker mx-auto my-auto';
  emojiIcon.style.fontSize = '1.4rem';
  const emojiElementBaseStyles = 'rounded-[10rem] h-9 w-9 mx-auto my-auto flex';

  if (
    !feature?.properties?.community_icon ||
    !feature?.properties?.community_color
  ) {
    emojiElement.className = 'bg-[#FFC547]  ';
    emojiIcon.innerHTML = renderEmoji('0x1F60E');
  } else {
    emojiElement.style.backgroundColor = feature?.properties?.community_color;
    // emojiElement.className = `${emojiElementBaseStyles} bg-opacity-60 bg-orange-200`;
    emojiElement.className = `${emojiElementBaseStyles}`;

    emojiIcon.innerHTML = renderEmoji(feature?.properties?.community_icon);
  }

  emojiElement.appendChild(emojiIcon);

  return emojiElement;
};

export const createActivityElement = (feature) => {
  const emojiElement = createEmojiElement(feature);
  const activityElement = document.createElement('div');
  activityElement.className = `rounded-[10rem] bg-white h-10 w-10 flex community-activity community-${feature?.properties?.community}-activity`;
  activityElement.id = `${feature?.properties?.activity_id}-map-display`;
  activityElement.appendChild(emojiElement);

  return activityElement;
};

export const createActivityPopup = (feature) =>
  !feature?.properties?.community_name
    ? new mapboxgl.Popup({
        offset: 25,
        className: 'custom-popup',
      }).setText('Vibe reported here!')
    : new mapboxgl.Popup({
        offset: 25,
        className: 'custom-popup',
      }).setText(`${feature?.properties?.community_name} reported here!`);

export const createActivityPopupElastic = (feature, communityData) => {
  const communityProperties = feature.properties;
  const communityName = communityData.name ?? 'flockx Activity';
  const message = `<div><span class="text-default">${checkAndGenerateName(
    communityProperties['twin:name']
  )}</span> reported <span class="text-default">${communityName}</span>.</div>
                <div>${communityProperties.description}</div>`;

  return !communityData
    ? new mapboxgl.Popup({
        offset: 25,
        className: 'custom-popup',
      }).setText('Vibe reported here!')
    : new mapboxgl.Popup({
        offset: 25,
        className: 'custom-popup',
      }).setHTML(`${message}`);
};

export const getBoundingBox = (lat, lng, distance) => {
  const latDistance = (69.172 * distance) / (1.609 * 1000);
  const lngDistance = (53.14 * distance) / (1.609 * 1000);

  const lat1 = lat - latDistance;
  const lng1 = lng - lngDistance;

  const lat2 = lat - latDistance;
  const lng2 = lng + lngDistance;

  const lat3 = lat + latDistance;
  const lng3 = lng + lngDistance;

  const lat4 = lat + latDistance;
  const lng4 = lng - lngDistance;

  const xmin = Math.min(lng1, lng2, lng3, lng4);
  const ymin = Math.min(lat1, lat2, lat3, lat4);
  const xmax = Math.max(lng1, lng2, lng3, lng4);
  const ymax = Math.max(lat1, lat2, lat3, lat4);

  return {
    xmin,
    ymin,
    xmax,
    ymax,
  };
};

export const createActivityPopup2 = (feature) => {
  const { user, location_name, description, community_name } =
    feature?.properties ?? {};

  return new mapboxgl.Popup({
    offset: 25,
    className: 'custom-popup',
  }).setHTML(
    `<div class="flex flex-col px-2">
                <div>
                    <span class="text-default">${user}</span> reported 
                    <span class="text-default">${description}</span> at 
                    <span class="text-default">${location_name}</span> for the 
                    <span class="text-default">${community_name}</span>
                </div>
        </div>`
  );
};

export const createPoiElement = (poi, marker) => {
  const imageElement = document.createElement('img');
  imageElement.src = marker;
  imageElement.style.height = '16 px';
  imageElement.style.width = '16 px';
  const poiElement = document.createElement('div');
  poiElement.className = 'w-[32px] h-[32px] flex rounded-[32px]';
  poiElement.id = `${poi.id}-map-display`;
  poiElement.appendChild(imageElement);
  return poiElement;
};

const googleMapsUrl = (latitude, longitude) =>
  `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
const appleMapsUrl = (latitude, longitude) =>
  `http://maps.apple.com/?q=${latitude},${longitude}`;

const constructNavigationUrl = (geometryToUse) => {
  if (
    geometryToUse &&
    typeof geometryToUse === 'object' &&
    geometryToUse.coordinates
  ) {
    const [longitude, latitude] = geometryToUse.coordinates;
    return googleMapsUrl(latitude, longitude);
  }
  if (geometryToUse && typeof geometryToUse === 'string') {
    try {
      const parsedGeometry = JSON.parse(geometryToUse);
      if (parsedGeometry && parsedGeometry.coordinates) {
        const [longitude, latitude] = parsedGeometry.coordinates;
        return googleMapsUrl(latitude, longitude);
      }
    } catch (e) {
      console.error('Error parsing geometry:', e);
    }
  }
  return appleMapsUrl(GRAND_RAPIDS_DEFAULT_LAT, GRAND_RAPIDS_DEFAULT_LNG); // Fallback to a default location if no valid geometry is provided
};

export const createPoiPopup = (poi) => {
  const { id, name, address, geometry } = poi;

  let navigationUrl = '#';
  navigationUrl = constructNavigationUrl(geometry);
  const linkClass = 'text-default underline';
  const linkTarget = '_blank';

  const alertMessage =
    'Thank you for exploring Uptown Grand Rapids and participating in Shop Hop. Enjoy your adventure! - Uptown Grand Rapids & Flockx';

  const handleLinkPress = () => {
    alert(alertMessage);
    if (typeof window.gtag !== 'function') {
      window.gtag('event', 'shop_hop_map_address_pressed', {
        link: linkTarget,
      });
    }
  };

  return new mapboxgl.Popup({
    offset: 25,
    className: 'custom-popup',
  }).setHTML(
    `<div key="${id}" class="flex flex-col px-2" onclick="if (typeof window.gtag !== 'function') {}">
    <div>
        <span class="text-default">${name}</span> at 
        <a href="${navigationUrl}" target="${linkTarget}" class="${linkClass}" onclick="${handleLinkPress} ${
      navigationUrl === '#' ? 'return false;' : ''
    }">${address}</a>
    </div>
</div>`
  );
};

export const createPolygonLayerOnMapBox = ({
  polygonDataSourceId,
  fillColor = '#6E3CFF',
  opacity = 0.5,
}) => ({
  id: polygonDataSourceId,
  type: 'fill',
  source: polygonDataSourceId,
  layout: {},
  paint: {
    'fill-color': fillColor,
    'fill-opacity': opacity,
  },
});
