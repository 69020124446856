import useSWR from 'swr';
import { fetchAPIFetcher } from '../../../utils/helpers/fetch-api';

export default function useQuest(questID) {
  const { data, error } = useSWR(`quests/${questID}/`, fetchAPIFetcher);

  return {
    quest: data,
    isLoading: !error && !data,
    isError: error
  };
}
