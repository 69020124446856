export const sixthStreetMapData = {
  mapBounds: JSON.stringify({
    xmax: -97.72604554694593,
    xmin: -97.75208848390326,
    ymax: 30.275285454700125,
    ymin: 30.259283278627677
  }),
  lng: -97.7391,
  lat: 30.2673,
  zoom: 14.86
};

export const mockGeoJSON = {
  type: 'FeatureCollection',
  features: [
    {
      id: '6f1a0b5a-79c8-4a83-9ec8-3a7405d6e235',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97.7361053884228, 30.27017387441201]
      },
      properties: {
        user: 'Dan Smith',
        location_name: 'Mohawk',
        description: 'Dr. Scientists are playing at 7:00pm',
        community_name: 'Live Music Lovers',
        community_color: '#8197E5',
        community_icon: 'U+1F3B8'
      }
    },
    {
      id: '8f7a6b5c-89d9-4e82-0db7-2c7316e7f434',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97.73624382432345, 30.268526631709452]
      },
      properties: {
        user: 'Rachel Chen',
        location_name: 'Stubb\'s BBQ',
        description: 'Come for the food, stay for the music!',
        community_name: 'Foodies and Music Enthusiasts',
        community_color: '#8197E5',
        community_icon: 'U+1F3B8'
      }
    },
    {
      id: '7d8c9e6f-67b6-5a49-1c2d-0e3f4a5b6c7d',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97.73981469030845, 30.26710938670593]
      },
      properties: {
        user: 'Juan Rodriguez',
        location_name: 'The Blind Pig Pub',
        description: 'Discover new indie artists!',
        community_name: 'Indie Music Fans',
        community_color: '#8197E5',
        community_icon: 'U+1F3B8'
      }
    },
    {
      id: '6a5b4c3d-2e1f-5d6c-7b8a-9f0e1d2c3b4a',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97.7402557653851, 30.266240284436694]
      },
      properties: {
        user: 'Alex Kim',
        location_name: 'Antone\'s Nightclub',
        description: 'Get ready to dance to some blues!',
        community_name: 'Blues and Jazz Lovers',
        community_color: '#8197E5',
        community_icon: 'U+1F3B8'
      }
    },
    {
      id: '1e2f3d4c-5b6a-7d8c-9e0f-1a2b3c4d5e6f',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97.7434906747499, 30.26681069583812]
      },
      properties: {
        user: 'Emily Lee',
        location_name: 'Speakeasy',
        description: 'Get ready to listen to some soul!',
        community_name: 'Soul Music Fans',
        community_color: '#8197E5',
        community_icon: 'U+1F3B8'
      }
    },
    {
      id: '9e8f7d6c-5b4a-3d2e-1f0e-2c3b4d5e6f7a',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97.73615492771498, 30.2674680649688]
      },
      properties: {
        user: 'Jessica Brown',
        location_name: 'Empire Control Room & Garage',
        description: 'EDM fans unite! This is the place to be!',
        community_name: 'EDM Fans of Austin',
        community_color: '#8197E5',
        community_icon: 'U+1F3B8'
      }
    },
    {
      id: '4c5d6e7f-8a9b-1c2d-3e4f-5a6b7c8d9e0f',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97.73657024314679, 30.267819748324037]
      },
      properties: {
        user: 'Maggie Hernandez',
        location_name: 'The 13th Floor',
        description: 'A classic Austin venue for all types of music!',
        community_name: 'Austin Music Scene Enthusiasts',
        community_color: '#8197E5',
        community_icon: 'U+1F3B8'
      }
    },
    {
      id: '7b8c9d0e-1f2a-3b4c-5d6e-7f8a9b0c1d2e',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97.73880203036425, 30.266666175401866]
      },
      properties: {
        user: 'David Kim',
        location_name: 'Pete\'s Dueling Piano Bar',
        description: 'Come enjoy some live jazz in a cozy setting!',
        community_name: 'Jazz and Blues Enthusiasts',
        community_color: '#8197E5',
        community_icon: 'U+1F3B8'
      }
    }
  ]
};
