import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import LoadingSpinner from '../atoms/animations/LoadingSpinner';
import {
  geoJsonSourceConfig,
  createActivityElement,
  createActivityPopup2
} from '../../utils/helpers/mapbox';
import { mockGeoJSON } from '../../utils/helpers/location-data';

import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = process.env.GATSBY_MAP_BOX_ACCESS_TOKEN;

function MapApp({ mapData, communityID }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(mapData.lng);
  const [lat, setLat] = useState(mapData.lat);
  const [zoom, setZoom] = useState(mapData.zoom);
  const popUpRef = useRef(
    new mapboxgl.Popup({ offset: 15, className: 'custom-popup' })
  );
  const [questGeoJson, setQuestGeoJson] = useState(mockGeoJSON);
  const [mapBounds, setMapBounds] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (Object.entries(questGeoJson).length !== 0) setIsLoading(false);
    console.log({ questGeoJson });
  }, [questGeoJson]);

  useEffect(() => {
    if (isLoading) return;
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [lng, lat],
      zoom: zoom,
      pitch: 0,
      interactive: false
    });

    map.current.on('load', () => {
      map.current.addSource(
        'quest-data',
        geoJsonSourceConfig(questGeoJson)
      );

      for (const feature of questGeoJson.features) {
        const activityElement = createActivityElement(feature);
        const activityPopup = createActivityPopup2(feature);

        new mapboxgl.Marker(activityElement)
          .setLngLat(feature?.geometry?.coordinates)
          .setPopup(activityPopup)
          .addTo(map.current);
      }
    });

    map.current.on('move', () => {
      const box = map.current.getBounds();
      const points = {
        xmin: box.getSouthWest().lng,
        ymin: box.getSouthWest().lat,
        xmax: box.getNorthEast().lng,
        ymax: box.getNorthEast().lat
      };
      setMapBounds(points);
    });

    return () => map.current.remove();
  }, [isLoading]);

  useEffect(() => {
    if (!map.current) return;
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  useEffect(() => {
    if (!map.current) return;
    popUpRef.current.remove();
  }, [zoom]);

  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (!map.current) return;
  }, [mapBounds]);

  return (
    <div className='relative h-full'>
      {isLoading ? (
        <div className='h-full w-full bg-default shadow-md animate-pulse'>
          <LoadingSpinner />
        </div>
      ) : (
        <div className='bg-secondary-red p-1 h-full w-full shadow-md'>
          <div
            ref={mapContainer}
            style={{
              width: '100%',
              height: '100%',
              overflow: 'hidden'
            }}
          />
        </div>
      )}
    </div>
  );
}

export default MapApp;
