export const fetchAPIHeaders = (contentType = 'vnd.api') => ({
  Authorization: `Token ${process.env.GATSBY_FETCH_TOKEN}`,
  'Content-Type': `application/${contentType}+json`,
  'X-Organization': process.env.GATSBY_ORG_ID,
  'X-Twin': process.env.GATSBY_TWIN_ID
});

export const fetchAPIFetcher = (path, contentType) => {
  const url = `${process.env.GATSBY_FETCH_API}${path}`;
  const config = {
    headers: fetchAPIHeaders(contentType)
  };

  // No longer returning res.data, but json, will break MapApp until MapApp is updated
  return fetch(url, config).then((res) => res.json());
};
