import React from 'react';

export default function BlogQuest({ quest }) {
  const videoID = quest.data.relationships.video.data.id;
  const [video] = quest.included.filter(
    (videoResource) => videoID === videoResource.id
  );
  const videoUrl = video.attributes.url;
  const [communityData] = quest.included.filter(
    (item) => item.type === 'communities'
  );
  const community = communityData.attributes.name;

  return (
    <div className='flex flex-col sm:flex-row justify-center mx-auto max-w-4xl p-2 gap-6 mt-10 sm:mt-0 mb-6'>
      <div className='p-4 flex justify-center items-start flex-col order-2 sm:order-1'>
        <h3 className='text-2xl mb-2'>{quest.data.attributes.name}</h3>
        <h3 className='text-xl mb-2'>
          <span className=''>Community</span>: {community}
        </h3>
        <h4 className='text-xl'>
          <span className='text-default'>flockx</span> user:{' '}
          {quest.data.attributes.twin_name}
        </h4>
      </div>
      <div className='flex items-center justify-center order-1 sm:order-2'>
        <div className='p-1 rounded-3xl bg-secondary-red md:w-[320px] lg:w-[300px]'>
          <video
            className='rounded-3xl'
            controls
            muted
            playsInline
            autoPlay
            loop
            width='100%'
          >
            <source
              src={videoUrl.replace('http://', 'https://')}
              type='video/mp4'
            />
          </video>
        </div>
      </div>
    </div>
  );
}
