import React from 'react';
import useQuest from '../../utils/hooks/swr/useQuest';
import BlogQuest from '../atoms/quests/BlogQuest';

export default function QuestShowcase({ questID }) {
  const { quest, isLoading, isError } = useQuest(questID);

  if (isError) return <div>failed to load</div>;

  if (isLoading) return <div>loading...</div>;

  if (quest) return <BlogQuest quest={quest} />;
}
