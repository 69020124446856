import React, { useState, useEffect } from 'react';
import { isBrowser } from '../../../utils/helpers/environment';

export default function MobilePhoneVideo({ videoURL }) {
  const [width, setWidth] = useState(isBrowser() ? window.innerWidth : 0);

  useEffect(() => {
    if (isBrowser()) {
      const handleResize = () => {
        setWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  const playerWidth = width < 768 ? 250 : 225;

  return (
    <div className='inline-block p-1 bg-secondary-orange rounded-xl'>
      <video controls autoPlay muted width={playerWidth} className='rounded-xl'>
        <source src={videoURL} />
      </video>
    </div>
  );
}
